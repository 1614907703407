/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService,
  combineHeadersOptions, commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable, map } from 'rxjs';
import {
  IGetLessonGroupsResponse,
  ILesson,
  IQuizProgressDetails,
  ISection
} from './interfaces';
import {
  ISectionData
} from '../../../common/tasks/legal-trainings/legal-trainings.reducer';
import { enumHasValue } from '../../../util';

import { VideoQualitiesEnum } from '../media/interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class LegalTrainingsService extends AbstractServerInteractionService {
  protected get url(): {
    lessonGroups: string,
    lesson: Function,
    quiz: Function,
    section: Function,
    question: Function
  } {
    return this.appService.getApiUrls().legalTrainings;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getLessonGroups(): Observable<IGetLessonGroupsResponse | any> {
    return this.http.get(this.url.lessonGroups);
  }

  public getLesson(id: string | number): Observable<ILesson | any> {
    return this.http.get(this.url.lesson(id));
  }

  public getQuiz(id: string | number): Observable<IQuizProgressDetails | any> {
    return this.http.get(this.url.quiz(id));
  }

  public getSection(id: string | number): Observable<ISection | any> {
    return this.http.get(this.url.section(id))
      .pipe(map(this.getSectionData));
  }

  public completeSection(id: string | number) {
    return this.http.post(this.url.section(id), null);
  }

  public answerQuestion(id, answer) {
    return this.http.post(this.url.question(id), answer);
  }

  public resetQuiz(id) {
    return this.http.post(this.url.quiz(id), null);
  }

  public completeTask() {
    return this.http.post(
      this.url.lessonGroups,
      null,
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    );
  }

  private getSectionData(section: ISection | any): ISectionData {
    const {
      sectionId,
      videoURLs,
      sectionTitle,
      sectionDescription
    } = section;
    const videos = {};

    videoURLs.forEach(({ quality, url }) => {
      if (!videos[quality] && enumHasValue(VideoQualitiesEnum, quality)) {
        videos[quality] = url;
      }
    });

    const qualityList = Object.keys(videos).sort();
    const currentVideoQuality = qualityList.find((elem) => elem === VideoQualitiesEnum.SD)
      || qualityList[0]
      || null;
    const currentVideoUrl = videos[currentVideoQuality] || null;

    return {
      sectionId,
      sectionTitle,
      sectionDescription,
      videos,
      qualityList,
      currentVideoUrl,
      currentVideoQuality,
      currentVideoPosition: 0
    };
  }
}
